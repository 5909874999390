import React from "react"
import PropTypes from "prop-types"
import "./Layout.scss"
import { Helmet } from "react-helmet";

const Layout = ({ children }) => {
  return (
    <div >
      <Helmet >
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
      </Helmet>
      <div>
        <main>{children}</main>
        <footer>
          <section style={{marginBottom:"0px"}}>
          </section>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
